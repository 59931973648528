<template>
    <div class="d-flex flex-nowrap align-center justify-center">
        <v-autocomplete
            v-model="valueLocal"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            :label="getAutocompleteLabel"
            :rules="rules"
            item-text="label"
            item-value="id"
            :disabled="isDisabled"
            :readonly="isReadonly"
            :dense="isDense"
            hide-no-data
            cache-items 
            clearable 
            multiple />
        <template v-if="false && canAdd">
            <!--TODO: This needs to be fixed in the future, beyond the scope of this pull request-->
            <v-btn
                text
                small
                class="ml-1"
                min-width="15px"
                max-width="20px"
                @click="add">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="dialog" max-width="500px">
                <app-form
                    :key="formKey"
                    v-model="editedItem"
                    :api-endpoint="schema.pascalPlural"
                    :fields="schema.fields"
                    :filter="filter"
                    :form-title="getAutocompleteLabel"
                    @cancel="closeDialog"
                    @open="dialog = true"
                    @input="onAdd" />
                <component
                    :is="addForm"
                    :key="formKey"
                    v-model="editedItem"
                    :filter="filter"
                    @input="onAdd"
                    @cancel="closeDialog" />
            </v-dialog>
        </template>
    </div>
</template>

<script>
import axios from "axios";
import schemaService from "@/services/schemaService";
import { equal, or } from "@/services/filtering"

export default {
    props: {
        value: {
            type: Array,
            default: null,
        },
        schema: {
            type: Object,
            default: null,
        },
        url: {
            //optional, will use default in schema
            type: String,
            default: null,
        },
        listUrl: {
            //optional, will use default in schema
            type: String,
            default: null,
        },
        label: {
            //optional, will use default in schema
            type: String,
            default: null,
        },
        rules: {
            type: Array,
            default: () => [],
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Object,
            default: null,
        },
        isDense: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
        isAddDisabled: {
            type: Boolean,
            default: false,
        },
        itemText: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            lastSearch: "",
            search: "",
            isLoading: false,
            from: null,
            to: null,
            autocompleteKey: 0,
            items: [],
            dialog: false,
            editedItem: null,
            formKey: 0,
        };
    },

    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        getAutocompleteLabel() {
            if (this.isEmpty(this.label) && !this.isEmpty(this.schema)) {
                return this.schema.titleSingular;
            }
            return this.label;
        },
        getListUrl() {
            if (this.isEmpty(this.listUrl)) {
                return "/api/" + this.schema.pascalPlural + "/List";
            }
            return this.listUrl;
        },
        getUrl() {
            if (this.isEmpty(this.url)) {
                return "/api/" + this.schema.pascalPlural;
            }
            return this.url;
        },
        canAdd() {
            return (
                !this.isAddDisabled &&
                !this.isDisabled &&
                !this.isReadonly &&
                !this.isEmpty(this.schema) &&
                this.canEdit(this.schema.pascalPlural)
            );
        },
    },

    watch: {
        async search(search) {
            if (this.isEmpty(search) || this.isEmpty(this.schema)) {
                return;
            }
            this.load(search);
        },
        schema() {
            if (this.isEmpty(this.schema)) {
                return;
            }
            this.load(this.search);
        },
        value: {
            immediate: true,
            async handler(value) {
                if (this.isEmpty(this.schema)) {
                    this.load();
                    return;
                }
                if (this.isEmpty(value) || Object.keys(value).every(e => value[e] === null)) {
                    this.load();
                    return;
                }
                // If the selected value (or values) are not available in the list, then load them. 
                let ids = value;
                let itemIds = this.items.map(i => i.label);
                if (!ids.some(id => !itemIds.includes(id))) {
                    // There are no ids missing, so we can return.
                    return;
                }
                let params = {};
                params.filter = or(ids.map(e => equal("Id", e, "Guid")));
                let response = await axios.post(this.getListUrl + "/", params);
                let entities = response.data.items;
                for (let i = 0; i < entities.length; i++) {
                    let entity = entities[i];
                    let label = await this.getLabel(entity, this.schema.fields);
                    let item = {
                        id: entity.id,
                        label: label,
                    };
                    this.items.push(item);
                }
            },
        },

        filter: {
            async handler() {
                await this.load(this.search);
                this.autocompleteKey++;
            },
        },
    },

    methods: {
        add() {
            this.formKey++;
            this.editedItem = null;
            this.dialog = true;
        },
        onAdd(item) {
            this.valueLocal.push(item.id);
            this.closeDialog();
        },
        closeDialog() {
            this.dialog = false;
        },
        async load(search) {
            this.lastSearch = search;
            this.isLoading = true;
            let stringFields = schemaService.getStringFields(this.schema);
            try {
                let params = {};
                let filters = [[]];
                let hasFilter = false
                let searchWords = !this.isEmpty(this.search) ? this.search.split(" ") : [];
                for (let i = 0; i < stringFields.length; i++) {
                    let field = stringFields[i];
                    for (let j = 0; j < searchWords.length; j++) {
                        let filter = {
                            $type: "String",
                            comparison: "StartsWith",
                            property: field.pascalSingular,
                            value: searchWords[j],
                        };
                        hasFilter = true;
                        filters[0].push(filter);
                    }
                }
                if (hasFilter) {
                    params.filters = filters;
                }
                let response = await axios.post(this.getListUrl, params);

                if (this.lastSearch === search) {
                    let items = response.data.items;
                    let idLabels = [];
                    if (!this.isEmpty(this.itemText)) {
                        idLabels = items.map(e => {
                            return {
                                id: e.id,
                                label: e[this.itemText]
                            };
                        });
                    }
                    else {
                        idLabels = await schemaService.getIdLabels(items, this.schema.fields);
                    }
                    this.items = idLabels;
                }
            } catch (error) {
                this.handleError(error);
            } finally {
                if (this.lastSearch === search) {
                    this.isLoading = false;
                }
            }
        },
    },
};
</script>
